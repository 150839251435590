/* VERIFICATION PAGE MODAL */
.modal-body{
    font-size: 1.2em;
    /* max-width: 400px; */
    margin: auto;
}

/* HEADER NAVBAR */
.logo {
    width: 80%;
    max-width: 200px;
}
.nav-brand-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.search-area{
    position: relative;
}
.search-area span{
    /* position: absolute;
    right: 20px;
    top: 20px; */
    position: relative;
    font-size: 1.5em;
    top: 10px;
}
.form-control{
    border: none;
}
.shrink-width{
    width: auto;
}


/* HEADER CAROUSEL */ 
.carousel-control-next,
.carousel-control-prev{
    z-index: 40;
}
.carouse-item{
    position: relative;
}
.overlay {
    z-index: 5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .5s ease;
    background-color: #00000091;
}
.carousel-caption{
    text-align: left;
    bottom: 90px;
}
.carousel-caption h3{
    font-size: 1.4em!important;
}
.carousel img {
    width: 70%;
    height: 400px;
}
.carousel-suv-image{
    width: 100%;
    height: 200px !important;
    position: absolute;
    top: 120px;
}

/* HOMEPAGE PRODUCT CATEGORIES */
.firstcolumn,
.firstrow,
.secondrow{
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--light-grey);
    padding: 2rem 1rem;
}
.product-category-grid img{
    width: 50%;
    max-width: 150px;
}

/* TESTIMONIAL SLIDER */
.swiper-container{
    height: 400px;
}

/* NEWSLETTER */
.newsletter-button{
    right: 20px;
    top: 2px;
}




@media(min-width: 768px){
    /* HEADER NAVBAR */
    .logo{
        width: 100%;
        max-width: 200px;
    }
    .nav-brand-wrapper{
        width: auto;
    }
    .grid-container {
        display: grid;
        margin: 0;
    }
    .navbar-grid-container{
        grid-template-areas:
          'search search search search icons'
          'navlinks navlinks navlinks navlinks icons';
        background-color: var(--grey); 
    }
    .navbar-expand-md .navbar-collapse {
        display: grid !important;
    }
    .navbar-expand{
        justify-content: space-between;
    }
    .navbar{
        padding: 0 1rem;
    }
    .navbar-nav{
        flex-wrap: wrap;
    }
    .search-area { 
        grid-area: search;
        border-left: 1px solid white;
        border-right: 1px solid white;
        padding: 10px;
    }
    .icon-area { 
        grid-area: icons; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .nav-area { 
        grid-area: navlinks; 
        border-top: 1px solid white;
        border-left: 1px solid white;
        border-right: 1px solid white;
        width: 100%;
        padding: 10px;
    }
    .navbar-toggler{
        display: none;
    }
    

    /* HEADER CAROUSEL */
    .carousel img {
        width: auto;
    }
    .carousel-caption{
        width: 400px;
        bottom: 90px;
    }
    .carousel-suv-image{
        right: 200px;
    }
    .carousel-caption h3{
        font-size: 1.5em!important;
    }

    /* TESTIMONIAL */
    .testimonial-wrapper{
        display: flex;
        justify-content: space-between;
    }
    .swiper-container{
        max-width: 1000px;
        height: 250px;
    }
}

@media(min-width: 1024px){
    /* HOMEPAGE PRODUCT CATEGORIES */
    /* .product-category-grid{
        grid-template-areas:
          'firstcolumn firstcolumn firstrow firstrow'
          'firstcolumn firstcolumn secondrow secondrow'; 
    } */
    .product-category-grid img{
        width: 50%;
        max-width: 300px;
    }
    .firstcolumn,
    .firstrow,
    .secondrow{
        justify-content: space-between;
        align-items: flex-start;
        padding: 2rem;
        /* max-width: 700px; */
        border-top: 0;
        transition: .5s ease-in-out;
    }
    .firstcolumn:hover,
    .firstrow:hover,
    .secondrow:hover{
        background: var(--lighter-grey);
    }
    .firstcolumn{
        grid-area: firstcolumn;
        flex-direction: column;
        justify-content: flex-start; 
    }
    .firstcolumn img {
        width: 100%;
        max-width: 350px;
    }
    #firstcolumn{
        border-left: 1px solid var(--light-grey);
    }
    .firstrow{
        grid-area: firstrow;
        border-left: 1px solid var(--light-grey);
    }
    #firstrow{
        border-left: 0;
    }
    .secondrow{
        grid-area: secondrow;
        border-top: 1px solid var(--light-grey);
        border-left: 1px solid var(--light-grey);
    }
    #secondrow{
        border-left: 0;
    }
    #secondgrid{
        border-top: 1px solid var(--light-grey);
    }
}