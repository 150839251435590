/* PRODUCT INFO SLIDE*/
.swiper-container-thumbs{
    height: 150px;
}
.product-slide{
    height: 300px;
}

/* PAYMENT TABS */
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active{
    background-color: transparent;
    color: var(--blue) ;
    border-bottom: 1px solid var(--blue);
}
.nav-tabs{
    border-bottom: 1px solid var(--grey);
}
.nav-tabs a{
    color: var(--dark-grey);
}
.btn-cancel:hover {
    background: rgb(247, 53, 53) !important;
}

/* CART */
.nav-link.active {
    color: var(--blue) ;
}
.nav-item a{
    color: var(--dark-grey);
}
#payment-tabpane-Cart .nav-link{
    padding: 0 20px 0 0;
}
.delete-text{
    position: relative;
    bottom: 4px;
}

/* SHIPPING DETAILS */
.shipping-info-card{
    transition: .5s ease-in;
}
.shipping-info-card:hover{
    background: var(--blue);
    color: white !important;
}
.shipping-card{
    border: none;
}
.payment-opt-color{
  background: #F2F2F2;
}

.form-check-input.is-valid~.form-check-label, 
.was-validated .form-check-input:valid~.form-check-label {
    color: var(--dark-grey);
}

/* ADD PRODUCT */
.multiSelectContainer {
    background: #E5E5E5;
}

/* PRODUCT TAG */
.input-tag {
    background:#E5E5E5;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5px 0;
  }
  
  .input-tag input {
    border: none;
    outline: none;
    width: 100%;
    background:#E5E5E5;
  }
  
  .input-tag__tags {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .input-tag__tags li {
    align-items: center;
    background: #333333;
    border-radius: 2px;
    color: white;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
  }
  
  .input-tag__tags li button {
    align-items: center;
    appearance: none;
    background: #fffafa;
    border: none;
    border-radius: 50%;
    color: #080808;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    transform: rotate(45deg);
    width: 15px;
  }
  
  .input-tag__tags li.input-tag__tags__input {
    background: none;
    flex-grow: 1;
    padding: 0;
  }
  .dynamic-input{
    padding: .5rem;
  }

  .paysuccess-icon{
      font-size: 130px !important;
      color: green !important;
  }
  .w-30{
      width: 30% !important;
  }

  .clear-cart{
    background-color:rgb(32, 146, 32) !important;
  }
  .clear-cart:hover{
    background-color:rgb(32, 146, 32) !important;
  }
  .cancel-clear{
    background-color:red !important;
  }
  .cancel-clear:hover{
    background-color:red !important;
  }

  .empty-btn{
    background-color:red !important;
  }
  .empty-btn:hover{
    background-color:red !important;
  }

  .disabled-btn{
    background-color:dimgrey !important;
    color: linen !important;
  }
  .disabled-btn:hover{
    background-color:dimgrey !important;
    color: linen !important;
    cursor:not-allowed;
  }

  .success-text{
    font-size:25px;
  }


@media(min-width:768px){
    /* PRODUCT INFO SLIDE */
    .swiper-container-thumbs{
        height: 250px;
        flex-direction: column;
    }
    #thumbs{
        width: 100%
    }

    /* PAYMENT TABS */
    .quantity h6{
        margin-left: 1rem;
    }

    /* SHIPPING DETAILS */
    .shipping-address{
        border-left: 1px solid #dee2e6;
    }
    .line{
        flex: 1;
        height: 1px;
        background: #dee2e6;
    }
    .payment-alt-text{
     padding: 0 2rem;
    }
}
@media(max-width:425px){
    /* PAYMENT */
    .payment-seperator-mobile{
        display: block !important;
        text-align: center !important;
    }
    .line{
        display: none !important;
    }
    .mobile-align{
        align-items: flex-start !important;
    }
}
@media(max-width:768px){
    /* DRAFT */
    .icon-space{
        margin: 1rem 0 !important
    }
    .paysuccess-icon{
        font-size:100px !important
    }
    .paysuccess-text{
        font-size: 1rem !important;
    }
}