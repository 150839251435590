/* Breadcrumbs */
.breadcrumb-item+.breadcrumb-item::before {
    color: var(--dark-blue);
    font-weight: 700;
    content: "\003E";
}
.breadcrumb-item.active {
    color: var(--dark-blue);
    font-weight: 700;
}
.breadcrumb a{
    color: var(--grey);
}
.breadcrumb{
    background: transparent;
}

/* SEARCHBAR */
.form-group{
    margin-bottom: 0;
}
.searchbar{
    width: 100%;
}
.sort-select{
    width: auto;
}
.sort-selection{
    width: 100%;
    justify-content: center;
}

@media(min-width:768px){
    .searchbar{
        width: 50%;
    }
    .sort-selection{
        width: 50%;
        justify-content: unset;
    }
}