/* VENDOR DASHBOARD OVERVIEW */
.overview-search{
    right: 20px;
    top: 7px;
}
.notification-icon {
    margin-top: 12px;
}
.order-1-mobile{
    order: 1;
}
.order-2-mobile{
    order: 2;
}
.width-60{
    width: 60px;
}
/* REMOVE BORDER COLOR ON MOBILE */
.side-navigation-panel 
.side-navigation-panel-select 
.side-navigation-panel-select-wrap 
.side-navigation-panel-select-option{
    border-left-width: 0 !important;
}
/* CHANGE COLOR OF ACTIVE SIDEBAR */
.side-navigation-panel .side-navigation-panel-select 
.side-navigation-panel-select-wrap 
.side-navigation-panel-select-option-selected {
    border-color: var(--blue) !important;
    color: var(--blue) !important;
    background-color: transparent !important;
}
/* CHANGE COLOR OF SIDEBAR ON HOVER */
.side-navigation-panel .side-navigation-panel-select 
.side-navigation-panel-select-wrap 
.side-navigation-panel-select-option:hover{
    border-color:var(--blue) !important;
}
/* SIDEBAR TEXT */
.side-navigation-panel .side-navigation-panel-select 
.side-navigation-panel-select-wrap .side-navigation-panel-select-option 
.side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text{
    text-align: right !important;
    margin: 0;
}
.sidebar{
    width: 100%;
    justify-content: flex-end;
}
.dashboard-container{
    display: flex;
    flex-direction: column;
}
/* PRODUCT NAVIGATION TABS */
/* .nav-tabs .nav-link{
    padding: 10px 20px 0 0;
} */

/* CHART */
.chart-wrapper{
    padding-bottom: 30px;
}
.chart-header input{
    max-width: 200px;
}
/*MAKE CHART LINES transparent */
.recharts-cartesian-grid-vertical{
    stroke-opacity: 0;
}
.recharts-cartesian-grid-horizontal{
    stroke-opacity: 0.2;
}
.recharts-cartesian-axis-line {
    stroke-opacity: 0;
}

.overview-products-card{
    margin-top: 20px;
}
/* OVERVIEW TABLES */
.table-responsive-xl{
    height: 300px;
    overflow-y: auto;
}
.table td,
.table th{
    vertical-align: middle;
}
tbody tr{
    background-color: rgba(0,0,0,.05);
}
.table-spacing{
    border-collapse: separate;
    border-spacing: 0 20px;
}

/* Modals */
.modal-details-container{
    position: fixed;
    z-index: 5000;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    right: 0;
    transition: .5s;
    background: whitesmoke;
    /* margin-top: 100px; */
}
.modal-container{
    z-index: 800;
    transition: .5s;
}

/* PREVIEW */
.preview-slide{
    height: 300px;
}

/* FileUpload */
 .thumbnail-div{
    display: flex;
    width: auto;
    height: 200px;
 }

 .thumbnail-img{
    display: block;
    width: auto;
    height: 80%;
  }

/* PROFILE */
.vendor-profile-icon-wrapper{
    transition: .5s ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.356);
    cursor: pointer;
}
.vendor-profile-icon-wrapper span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

@media(max-width: 360px){
    .chart-header input{
        max-width: 120px;
    }
}
@media(min-width:768px){
    .order-1-mobile{
        order: 2;
    }
    .order-2-mobile{
        order: 1;
    }
    .sidebar{
        width: 25%;
        justify-content: flex-start;
    }
    /* ADD BORDER COLOR ON WEB */
    .side-navigation-panel 
    .side-navigation-panel-select 
    .side-navigation-panel-select-wrap 
    .side-navigation-panel-select-option{
        border-left-width: 3px !important;
    }
    /* CHANGE COLOR OF ACTIVE SIDEBAR */
    .side-navigation-panel .side-navigation-panel-select 
    .side-navigation-panel-select-wrap 
    .side-navigation-panel-select-option-selected {
        background-color: var(--light-blue) !important;
    }
    .dashboard-container{
        display: flex;
        flex-direction: row;
    }
    .modal-details-container{
        width: 80%;
        margin-top: 0;
        height: 100vh;
    }
    .add-product-wrapper{
        padding: 2rem;
    }
}
@media(min-width:1024px){
    .overview-products-card{
        margin-top: 0;
    }
    .table-responsive-xl{
        height: 300px;
        overflow-y: auto;
    }
}
@media(max-width:768px){
    .thumbnail-img{
        width: 90%;
      }
      .preview-slide{
        height: 300px;
    }
    .ipad-margin{
        margin-left: 1.5rem !important;
    }
    .preview-info{
        margin: 0 auto;
    }
    .preview-info2{
        margin:  2rem auto;
    }
    .linked-acct-mobile{
        width: 85%;
    }
    .delete-acct-mobile{
        width: 75%;
    }
}
@media(max-width:375px){
    .thumbnail-img{
        width: 90%;
    }
}

