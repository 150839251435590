@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
/* main {
  min-height: 80vh;
}
h3 {
  padding: 1rem 0;
}

.d-flex .form-control {
  border-radius: 5px;
}
.d-flex i {
  color: white;
  margin-top: 10px;
}

.navColor {
  background: #C4C4C4;
  color: black;
}

.form-container {
  max-width: 500px;
  margin: 2rem auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 70px;
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}
.carousel-caption h2 {
  color: #fff;
}
.carousel img {
  height: 500px;
  padding: 30px;
  margin: 40px;
  border-radius: 30px;
}
.carousel a {
  margin: 0 auto;
}


.container-fluid {
  overflow: hidden;
  margin-top: 250px;
  background: #003E84;
  color: #fff !important;
  margin-bottom: 0;
  padding-bottom: 0
}

small {
  font-size: calc(12px + (15 - 12) * ((100vw - 360px) / (1600 - 360))) !important
}

.bold-text {
  color: #989c9e !important
}

.mt-55 {
  margin-top: calc(50px + (60 - 50) * ((100vw - 360px) / (1600 - 360))) !important
}

h3 {
  font-size: calc(34px + (40 - 34) * ((100vw - 360px) / (1600 - 360))) !important
}

.social {
  font-size: 21px !important
}

.rights {
  font-size: calc(10px + (12 - 10) * ((100vw - 360px) / (1600 - 360))) !important
}

.container-box {
  margin-top: 200px;
  color: #000 !important
}
.card {
  color: #757575 !important;
  background-color: #fff !important;
  align-items: center !important;
  background: url('https://i.imgur.com/9djv5sH.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right calc(110px + (150 - 110) * ((100vw - 360px) / (1600 - 360))) bottom !important
}
button {
  font-size: calc(12px + (13 - 12) * ((100vw - 360px) / (1600 - 320))) !important;
  padding-left: calc(20px + (28 - 20) * ((100vw - 360px) / (1600 - 360))) !important;
  padding-right: calc(20px + (28 - 20) * ((100vw - 360px) / (1600 - 360))) !important;
  padding-top: calc(10px + (12 - 10) * ((100vw - 360px) / (1600 - 360))) !important;
  padding-bottom: calc(10px + (12 - 10) * ((100vw - 360px) / (1600 - 360))) !important
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0
}
input {
  border: 2px solid green !important
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
  height: calc(40px + (48 - 40) * ((100vw - 360px) / (1600 - 360))) !important
}

input::-webkit-input-placeholder {
  padding-left: 5px !important;
  transform: translate3d(0, -4px, 0)
}

input::placeholder {
  vertical-align: middle !important;
  font-size: calc(12px + (14 - 12) * ((100vw - 360px) / (1600 - 360))) !important
}

input:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0
} */
:root {
    --blue: #0981C2;
    --dark-blue: #003E84;
    --light-blue: #F0F9FE;
    --gray: #BDBDBD;
    --grey: #DBDBDB;
    --light-grey: #F2F2F2;
    --lighter-grey: #e5e5e52d;
    --dark-grey: #333333;
}
/* TEXT */
.text-white{
    color: white !important;
}
.text-red{
    color: red;
}
.text-green{
    color: #42C884;
}
.text-blue{
    color: var(--blue) !important;
}
.text-dark-blue{
    color: var(--dark-blue);
}
.text-grey{
    color: var(--grey)
}
.text-light-grey{
    color: var(--light-grey);
}
.text-dark-grey{
    color: var(--dark-grey)
}
.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}

.font-bold{
    font-weight: bold;
}
.font-large{
    font-size: 2.5rem;
}
.font-size-base{
    font-size: 1rem
}/* Assumes the browser default, typically `16px` */
.font-size-sm{
    font-size: calc(1rem * .875);
}
.font-size-lg{
    font-size: calc(1rem * 1.25);
}
h1{
    font-size: calc(1rem * 2.5);
}
h2{
    font-size: calc(1rem * 2);
}
h3 {
    font-size: calc(1rem * 1.75)
}
h4{
    font-size: calc(1rem * 1.5)
}
h5{
    font-size: calc(1rem * 1.25);
}
.underline-heading{
    position: relative;
    padding: 0 0 10px 0;
}
.underline-heading:before{
    content: "";
    position: absolute;
    width: 25px;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid var(--blue);
}
.lh-base{
    line-height: 1.5rem;
}
.lh-sm{
    line-height: 1.25rem;
}
.lh-lg{
    line-height: 2;
}
.text-bold{
    font-weight: bold;
}

/* BORDER */
.border-blue{
    border: 1px solid var(--blue);
}
.border-red{
    border: 1px solid red;
}
.border-light-grey{
    border: 1px solid var(--grey) !important;
}
.rounded-3{
    border-radius: 3px;
}
.rounded-half{
    border-radius: 5rem !important; 
}
.rounded-10{
    border-radius: 10rem;
}
.rounded-full{
    border-radius: 50%;
}
.border-top{
    border-top: 1px solid var(--light-grey);
}
.border-left{
    border-left: 1px solid var(--light-grey);
}
.border-left-white{
    border-left: 1px solid white;
}
.border-right{
    border-right: 1px solid var(--light-grey);
}
.border-bottom{
    border-bottom: 1px solid var(--grey);
}
.border-dashed{
    /* border-style: dashed #E5E5E5; */
    border: 2px dashed grey;
}

/* BACKGROUND */
.bg-transparent{
    background: transparent;
}
.bg-white{
    background: white;
}
.bg-red{
    background: red;
}
.bg-green{
    background: green;
}
.bg-light-green{
    background: rgba(186, 252, 186, 0.5);
}
.bg-light-pink{
    background: rgba(255, 192, 203, 0.301);
}
.bg-light-yellow{
    background: rgba(255, 255, 147, 0.404);
}
.text-pink{
    color: rgb(190, 27, 136);
}
.text-yellow{
    color: rgb(116, 116, 11);
}
.bg-blue{
    background: var(--blue);
}
.bg-dark-blue{
    background: var(--dark-blue);
}
.bg-light-blue{
    background: var(--light-blue);
}
.bg-grey{
    background: var(--grey)
}
.bg-light-grey{
    background: var(--light-grey)
}
.bg-lighter-grey{
    background: var(--lighter-grey)
}
.bg-dark-grey{
    background: var(--dark-grey);
}
.bg-gray{
    background: var(--gray);
    opacity: .80;
}


/* BUTTONS */
button:hover{
    background: var(--dark-blue) !important;
    border: var(--dark-blue);
    color: white;
}
button{
    background: var(--blue);
    border-radius: 3px;
    color: white;
}

/* WIDTHS */
.width-10{
    width: 10px;
}
.width-20{
    width: 20px!important;
}
.width-30{
    width: 30px!important;
}
.width-40{
    width: 40px!important;
}
.width-25{
    width: 25px;
}
.width-60-px{
    width: 60px;
}
.width-50{
    width: 50px;
}
.width-60{
    width: 60%;
}
.width-20{
    width: 45%;
}
.w-100{
    width:20%
}
.width-65{
    width: 65%;
}
.width-70{
    width: 70%;
}
.w-100{
    width: 78%;
}
.width-80{
    width: 80%;
}
.width-85{
    width: 85%;
}
.width-90{
    width: 90%;
}
.width-95{
    width: 95%;
}
.width-100{
    width: 100px;
}
.width-150{
    width: 150px;
}
.w-200{
    width: 200px;
}
.w-350{
    width: 350px;
}
.w-90{
    width: 90%
}
.mw-100{
    max-width: 100%;
}
.mw-200{
    max-width: 200px;
}
.mw-300{
    max-width: 300px;
}
.mw-360{
    max-width: 360px;
}
.mw-400{
    max-width: 400px;
}
.mw-500{
    max-width: 500px;
}
.mw-600{
    max-width: 600px;
}
.mw-700{
    max-width: 700px;
}
.mw-750{
    max-width: 750px;
}
.mw-800{
    max-width: 800px;
}
.mw-900{
    max-width: 900px;
}
.mw-rem-50{
    max-width: 50rem;
}
.max-width{
    max-width: 400px;
}
.h-50{
    height: 50% !important;
}
.h-100{
    height: 100% !important;
}
.h-450{
    height: 450px !important;
}
.height-auto{
    height: auto;
}
.height-fit{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.heigth-50-px{
    height: 50px;
}
.height-25{
    height: 25px;
}
.height-50{
    height: 50px;
}
.height-60{
    height: 60px;
}
.height-100{
    height: 100px;
}
.height-150{
    height: 150px;
}
.height-200{
    height: 200px !important;
}
.height-300{
    height: 300px;
}
.height-400{
    height: 400px;
}
.height-500{
    height: 500px;
}
.max-height{
    max-height: 100%;
}

/* DISPLAYS & POSITION */
.opacity-1{
    opacity: 1;
}
.flex-on-mobile{
    display: -webkit-flex;
    display: flex;
}
.hide-on-mobile{
    display: none;
}
.m-auto{
    margin: auto;
}
.block{
    display: block;
}
.inline{
    display: inline;
}
.object-fit-cover{
    object-fit: cover;
}
.object-bottom{
    object-position: bottom;
}
.object-center{
    object-position: center;
}
.cursor-pointer{
    cursor: pointer;
}
.order-1{
    -webkit-order: 1;
            order: 1;
}
.order-2{
    -webkit-order: 2;
            order: 2;
}
.z-index-100{
    z-index: 100;
}
.float-left{
    float: left;
}
.top-0{
    top: 0
}
.left-0{
    left: 0
}
.bottom-0{
    bottom: 0
}
.top-0{
    right: 0
}
.float-right{
    float: right;
}
.flex-end{
    -webkit-align-items: flex-end;
            align-items: flex-end;
}
.overflow-y{
    overflow-y: scroll;
}
.p-bottom45{
    padding-bottom: 4.5rem;
}

.logout-mag{
    margin: 3rem 0;
}

/* .width-300{
    width: 300px;
} */



@media(min-width:768px){
    .max-width{
        max-width: 360px;
    }
    .flex-on-mobile{
        display: block;
    }
    .hide-on-mobile{
        display: block;
    }
}
@media(max-width:768px){
    .w-100{
        width: 100% !important;
    }
    .logout-mag{
        margin: 0px;
    }
    .direction-col{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .mobile-margin{
        margin-bottom: 3rem;
    }
}


/* VERIFICATION PAGE MODAL */
.modal-body{
    font-size: 1.2em;
    /* max-width: 400px; */
    margin: auto;
}

/* HEADER NAVBAR */
.logo {
    width: 80%;
    max-width: 200px;
}
.nav-brand-wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
}
.search-area{
    position: relative;
}
.search-area span{
    /* position: absolute;
    right: 20px;
    top: 20px; */
    position: relative;
    font-size: 1.5em;
    top: 10px;
}
.form-control{
    border: none;
}
.shrink-width{
    width: auto;
}


/* HEADER CAROUSEL */ 
.carousel-control-next,
.carousel-control-prev{
    z-index: 40;
}
.carouse-item{
    position: relative;
}
.overlay {
    z-index: 5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .5s ease;
    background-color: #00000091;
}
.carousel-caption{
    text-align: left;
    bottom: 90px;
}
.carousel-caption h3{
    font-size: 1.4em!important;
}
.carousel img {
    width: 70%;
    height: 400px;
}
.carousel-suv-image{
    width: 100%;
    height: 200px !important;
    position: absolute;
    top: 120px;
}

/* HOMEPAGE PRODUCT CATEGORIES */
.firstcolumn,
.firstrow,
.secondrow{
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    border-top: 1px solid var(--light-grey);
    padding: 2rem 1rem;
}
.product-category-grid img{
    width: 50%;
    max-width: 150px;
}

/* TESTIMONIAL SLIDER */
.swiper-container{
    height: 400px;
}

/* NEWSLETTER */
.newsletter-button{
    right: 20px;
    top: 2px;
}




@media(min-width: 768px){
    /* HEADER NAVBAR */
    .logo{
        width: 100%;
        max-width: 200px;
    }
    .nav-brand-wrapper{
        width: auto;
    }
    .grid-container {
        display: grid;
        margin: 0;
    }
    .navbar-grid-container{
        grid-template-areas:
          'search search search search icons'
          'navlinks navlinks navlinks navlinks icons';
        background-color: var(--grey); 
    }
    .navbar-expand-md .navbar-collapse {
        display: grid !important;
    }
    .navbar-expand{
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .navbar{
        padding: 0 1rem;
    }
    .navbar-nav{
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .search-area { 
        grid-area: search;
        border-left: 1px solid white;
        border-right: 1px solid white;
        padding: 10px;
    }
    .icon-area { 
        grid-area: icons; 
        display: -webkit-flex; 
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    .nav-area { 
        grid-area: navlinks; 
        border-top: 1px solid white;
        border-left: 1px solid white;
        border-right: 1px solid white;
        width: 100%;
        padding: 10px;
    }
    .navbar-toggler{
        display: none;
    }
    

    /* HEADER CAROUSEL */
    .carousel img {
        width: auto;
    }
    .carousel-caption{
        width: 400px;
        bottom: 90px;
    }
    .carousel-suv-image{
        right: 200px;
    }
    .carousel-caption h3{
        font-size: 1.5em!important;
    }

    /* TESTIMONIAL */
    .testimonial-wrapper{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .swiper-container{
        max-width: 1000px;
        height: 250px;
    }
}

@media(min-width: 1024px){
    /* HOMEPAGE PRODUCT CATEGORIES */
    /* .product-category-grid{
        grid-template-areas:
          'firstcolumn firstcolumn firstrow firstrow'
          'firstcolumn firstcolumn secondrow secondrow'; 
    } */
    .product-category-grid img{
        width: 50%;
        max-width: 300px;
    }
    .firstcolumn,
    .firstrow,
    .secondrow{
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        padding: 2rem;
        /* max-width: 700px; */
        border-top: 0;
        transition: .5s ease-in-out;
    }
    .firstcolumn:hover,
    .firstrow:hover,
    .secondrow:hover{
        background: var(--lighter-grey);
    }
    .firstcolumn{
        grid-area: firstcolumn;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: flex-start;
                justify-content: flex-start; 
    }
    .firstcolumn img {
        width: 100%;
        max-width: 350px;
    }
    #firstcolumn{
        border-left: 1px solid var(--light-grey);
    }
    .firstrow{
        grid-area: firstrow;
        border-left: 1px solid var(--light-grey);
    }
    #firstrow{
        border-left: 0;
    }
    .secondrow{
        grid-area: secondrow;
        border-top: 1px solid var(--light-grey);
        border-left: 1px solid var(--light-grey);
    }
    #secondrow{
        border-left: 0;
    }
    #secondgrid{
        border-top: 1px solid var(--light-grey);
    }
}
/* Breadcrumbs */
.breadcrumb-item+.breadcrumb-item::before {
    color: var(--dark-blue);
    font-weight: 700;
    content: "\003E";
}
.breadcrumb-item.active {
    color: var(--dark-blue);
    font-weight: 700;
}
.breadcrumb a{
    color: var(--grey);
}
.breadcrumb{
    background: transparent;
}

/* SEARCHBAR */
.form-group{
    margin-bottom: 0;
}
.searchbar{
    width: 100%;
}
.sort-select{
    width: auto;
}
.sort-selection{
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
}

@media(min-width:768px){
    .searchbar{
        width: 50%;
    }
    .sort-selection{
        width: 50%;
        -webkit-justify-content: unset;
                justify-content: unset;
    }
}
/* PRODUCT INFO SLIDE*/
.swiper-container-thumbs{
    height: 150px;
}
.product-slide{
    height: 300px;
}

/* PAYMENT TABS */
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active{
    background-color: transparent;
    color: var(--blue) ;
    border-bottom: 1px solid var(--blue);
}
.nav-tabs{
    border-bottom: 1px solid var(--grey);
}
.nav-tabs a{
    color: var(--dark-grey);
}
.btn-cancel:hover {
    background: rgb(247, 53, 53) !important;
}

/* CART */
.nav-link.active {
    color: var(--blue) ;
}
.nav-item a{
    color: var(--dark-grey);
}
#payment-tabpane-Cart .nav-link{
    padding: 0 20px 0 0;
}
.delete-text{
    position: relative;
    bottom: 4px;
}

/* SHIPPING DETAILS */
.shipping-info-card{
    transition: .5s ease-in;
}
.shipping-info-card:hover{
    background: var(--blue);
    color: white !important;
}
.shipping-card{
    border: none;
}
.payment-opt-color{
  background: #F2F2F2;
}

.form-check-input.is-valid~.form-check-label, 
.was-validated .form-check-input:valid~.form-check-label {
    color: var(--dark-grey);
}

/* ADD PRODUCT */
.multiSelectContainer {
    background: #E5E5E5;
}

/* PRODUCT TAG */
.input-tag {
    background:#E5E5E5;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 5px 5px 0;
  }
  
  .input-tag input {
    border: none;
    outline: none;
    width: 100%;
    background:#E5E5E5;
  }
  
  .input-tag__tags {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .input-tag__tags li {
    -webkit-align-items: center;
            align-items: center;
    background: #333333;
    border-radius: 2px;
    color: white;
    display: -webkit-flex;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
  }
  
  .input-tag__tags li button {
    -webkit-align-items: center;
            align-items: center;
    -webkit-appearance: none;
            appearance: none;
    background: #fffafa;
    border: none;
    border-radius: 50%;
    color: #080808;
    cursor: pointer;
    display: -webkit-inline-flex;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    -webkit-justify-content: center;
            justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    width: 15px;
  }
  
  .input-tag__tags li.input-tag__tags__input {
    background: none;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 0;
  }
  .dynamic-input{
    padding: .5rem;
  }

  .paysuccess-icon{
      font-size: 130px !important;
      color: green !important;
  }
  .w-30{
      width: 30% !important;
  }

  .clear-cart{
    background-color:rgb(32, 146, 32) !important;
  }
  .clear-cart:hover{
    background-color:rgb(32, 146, 32) !important;
  }
  .cancel-clear{
    background-color:red !important;
  }
  .cancel-clear:hover{
    background-color:red !important;
  }

  .empty-btn{
    background-color:red !important;
  }
  .empty-btn:hover{
    background-color:red !important;
  }

  .disabled-btn{
    background-color:dimgrey !important;
    color: linen !important;
  }
  .disabled-btn:hover{
    background-color:dimgrey !important;
    color: linen !important;
    cursor:not-allowed;
  }

  .success-text{
    font-size:25px;
  }


@media(min-width:768px){
    /* PRODUCT INFO SLIDE */
    .swiper-container-thumbs{
        height: 250px;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    #thumbs{
        width: 100%
    }

    /* PAYMENT TABS */
    .quantity h6{
        margin-left: 1rem;
    }

    /* SHIPPING DETAILS */
    .shipping-address{
        border-left: 1px solid #dee2e6;
    }
    .line{
        -webkit-flex: 1 1;
                flex: 1 1;
        height: 1px;
        background: #dee2e6;
    }
    .payment-alt-text{
     padding: 0 2rem;
    }
}
@media(max-width:425px){
    /* PAYMENT */
    .payment-seperator-mobile{
        display: block !important;
        text-align: center !important;
    }
    .line{
        display: none !important;
    }
    .mobile-align{
        -webkit-align-items: flex-start !important;
                align-items: flex-start !important;
    }
}
@media(max-width:768px){
    /* DRAFT */
    .icon-space{
        margin: 1rem 0 !important
    }
    .paysuccess-icon{
        font-size:100px !important
    }
    .paysuccess-text{
        font-size: 1rem !important;
    }
}
/* VENDOR DASHBOARD OVERVIEW */
.overview-search{
    right: 20px;
    top: 7px;
}
.notification-icon {
    margin-top: 12px;
}
.order-1-mobile{
    -webkit-order: 1;
            order: 1;
}
.order-2-mobile{
    -webkit-order: 2;
            order: 2;
}
.width-60{
    width: 60px;
}
/* REMOVE BORDER COLOR ON MOBILE */
.side-navigation-panel 
.side-navigation-panel-select 
.side-navigation-panel-select-wrap 
.side-navigation-panel-select-option{
    border-left-width: 0 !important;
}
/* CHANGE COLOR OF ACTIVE SIDEBAR */
.side-navigation-panel .side-navigation-panel-select 
.side-navigation-panel-select-wrap 
.side-navigation-panel-select-option-selected {
    border-color: var(--blue) !important;
    color: var(--blue) !important;
    background-color: transparent !important;
}
/* CHANGE COLOR OF SIDEBAR ON HOVER */
.side-navigation-panel .side-navigation-panel-select 
.side-navigation-panel-select-wrap 
.side-navigation-panel-select-option:hover{
    border-color:var(--blue) !important;
}
/* SIDEBAR TEXT */
.side-navigation-panel .side-navigation-panel-select 
.side-navigation-panel-select-wrap .side-navigation-panel-select-option 
.side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text{
    text-align: right !important;
    margin: 0;
}
.sidebar{
    width: 100%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
.dashboard-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
/* PRODUCT NAVIGATION TABS */
/* .nav-tabs .nav-link{
    padding: 10px 20px 0 0;
} */

/* CHART */
.chart-wrapper{
    padding-bottom: 30px;
}
.chart-header input{
    max-width: 200px;
}
/*MAKE CHART LINES transparent */
.recharts-cartesian-grid-vertical{
    stroke-opacity: 0;
}
.recharts-cartesian-grid-horizontal{
    stroke-opacity: 0.2;
}
.recharts-cartesian-axis-line {
    stroke-opacity: 0;
}

.overview-products-card{
    margin-top: 20px;
}
/* OVERVIEW TABLES */
.table-responsive-xl{
    height: 300px;
    overflow-y: auto;
}
.table td,
.table th{
    vertical-align: middle;
}
tbody tr{
    background-color: rgba(0,0,0,.05);
}
.table-spacing{
    border-collapse: separate;
    border-spacing: 0 20px;
}

/* Modals */
.modal-details-container{
    position: fixed;
    z-index: 5000;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    right: 0;
    transition: .5s;
    background: whitesmoke;
    /* margin-top: 100px; */
}
.modal-container{
    z-index: 800;
    transition: .5s;
}

/* PREVIEW */
.preview-slide{
    height: 300px;
}

/* FileUpload */
 .thumbnail-div{
    display: -webkit-flex;
    display: flex;
    width: auto;
    height: 200px;
 }

 .thumbnail-img{
    display: block;
    width: auto;
    height: 80%;
  }

/* PROFILE */
.vendor-profile-icon-wrapper{
    transition: .5s ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.356);
    cursor: pointer;
}
.vendor-profile-icon-wrapper span{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

@media(max-width: 360px){
    .chart-header input{
        max-width: 120px;
    }
}
@media(min-width:768px){
    .order-1-mobile{
        -webkit-order: 2;
                order: 2;
    }
    .order-2-mobile{
        -webkit-order: 1;
                order: 1;
    }
    .sidebar{
        width: 25%;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
    }
    /* ADD BORDER COLOR ON WEB */
    .side-navigation-panel 
    .side-navigation-panel-select 
    .side-navigation-panel-select-wrap 
    .side-navigation-panel-select-option{
        border-left-width: 3px !important;
    }
    /* CHANGE COLOR OF ACTIVE SIDEBAR */
    .side-navigation-panel .side-navigation-panel-select 
    .side-navigation-panel-select-wrap 
    .side-navigation-panel-select-option-selected {
        background-color: var(--light-blue) !important;
    }
    .dashboard-container{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
    }
    .modal-details-container{
        width: 80%;
        margin-top: 0;
        height: 100vh;
    }
    .add-product-wrapper{
        padding: 2rem;
    }
}
@media(min-width:1024px){
    .overview-products-card{
        margin-top: 0;
    }
    .table-responsive-xl{
        height: 300px;
        overflow-y: auto;
    }
}
@media(max-width:768px){
    .thumbnail-img{
        width: 90%;
      }
      .preview-slide{
        height: 300px;
    }
    .ipad-margin{
        margin-left: 1.5rem !important;
    }
    .preview-info{
        margin: 0 auto;
    }
    .preview-info2{
        margin:  2rem auto;
    }
    .linked-acct-mobile{
        width: 85%;
    }
    .delete-acct-mobile{
        width: 75%;
    }
}
@media(max-width:375px){
    .thumbnail-img{
        width: 90%;
    }
}



.profile-area{
    width: 78%;
    margin-left: 15rem;
    margin-top: 5rem;
}
/* .profile-nav{
    width: 100% !important;
}
.profile-form{
    width: 100% !important;
} */
.cursor{
    cursor: pointer;
}
.m-top{
    margin-top:2.5rem
}

.camera-icon-wrapper, .banner-icon-wrapper{
    transition: .5s ease;
    position: absolute;
    top: -30px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.61);
    cursor: pointer;
}

.camera-icon-wrapper:hover{
    opacity: 1;
}
.camera-icon-wrapper>span{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

.avatar-wrapper{
    position: relative;
    width: 100px;
    height: 100px;
    margin: 50px 0 0 0;
    border-radius: 50%;
}
.avatar-wrapper>img{
    width: 100%;
    height: 100%;
    display: block;
    margin: -30px auto 0 auto;
    border-radius: 50%;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    opacity: 1;
    object-fit: cover;
}

.banner-icon-wrapper{
    border-radius: 0 !important;
}

@media(max-width:425px){
    .avatar-wrapper {
        width: 70px;
        height: 70px;
        margin: 40px -15px -25px;
    }
}
@media(min-width: 700px) and (max-width: 768px){
    .profile-form{
        /* width: 100% !important; */
    }
    /* .profile-nav{
        width: 25%;
    } */
}
@media(min-width: 1024px){
    .profile-form{
        width: 75%;
    }
}
@media(max-width:768px){
    
    .mobile-margin{
        margin-bottom: 3rem;
    }
    .profile-area{
        width: 100% !important;
        margin-left: 0 !important;
    }
}
@media(max-width:1024px){
    .w-100{
        width: 25%;
    }
    .w-100{
        width: 72%;
    }
    .profile-area{
        width: 72%;
        margin-left: 16rem;
    }
}

@media(min-width: 768px){
    .container-scroll{
        overflow-x: scroll!important;
    }
}


*{
    margin: 0;
}
html, body{
    margin: 0;
    padding: 0;
    font-family:Lato,sans-serif;
    /* background: var(--light-grey); */
}
p{
    color: grey;
}
/* Pagination */
.page-link{
    background: transparent;
    color: var(--dark-blue);
}
.page-item.active .page-link{
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
}

/* alert */
.alert{
    width: 300px;
    text-align: center;
    left: 50%;
    top: 0;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    -webkit-animation: moveInTop .5s ease-in;
            animation: moveInTop .5s ease-in;
}

@-webkit-keyframes moveInTop {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-3rem);
              transform: translateX(-3rem);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
    }
  }

@keyframes moveInTop {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-3rem);
              transform: translateX(-3rem);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
    }
  }

.main{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.mainData{
    max-width: 500px;
    max-height: 600px;
}
.pageNumbers {
    list-style: none;
    display: -webkit-flex;
    display: flex;
}

.pageNumbers li{
    padding: 10px;
    border: 1px solid white;
}

.pageNumbers li.active {
    background-color: white;
    color: black;

}

.pageNumbers li.button{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor:pointer;
}

.pageNumbers li.button:hover{
    background-color: white;
    color: black;
}

.pageNumbers button:disabled{
    background-color: grey;
}
.pageNumbers button{
    border: none !important;
}
.pageNumbers button:disabled:hover{
    background-color: grey !important;
    cursor: not-allowed;
}


.pageNumbers li.button:focus{
    outline: none;
}
.pagination_paginationWrapper__2--VN {
    padding: 1.5rem 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.pagination_separator__q9mPR {
    width: 1rem;
    margin: 0 0.25rem;
}
.pagination_pageItem__28bGu {
    background: transparent;
    border: none;
    height: 2rem;
    width: 2rem;
    margin: 0 0.25rem;
    border-radius: 5px;
    font-weight: 600;
    color: #626368;
}
.pagination_pageItem__28bGu:hover {
    text-decoration: underline;
}
.pagination_pageItem__28bGu:focus {
    outline: 0;
}
.pagination_active__7hi2T {
    background-color: blue;
    color: white;
}
.pagination_sides__1mA5k {
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
.pagination_sides__1mA5k:hover {
    text-decoration: none;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 6px 16px;
}

