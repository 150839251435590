.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainData{
    max-width: 500px;
    max-height: 600px;
}
.pageNumbers {
    list-style: none;
    display: flex;
}

.pageNumbers li{
    padding: 10px;
    border: 1px solid white;
}

.pageNumbers li.active {
    background-color: white;
    color: black;

}

.pageNumbers li.button{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor:pointer;
}

.pageNumbers li.button:hover{
    background-color: white;
    color: black;
}

.pageNumbers button:disabled{
    background-color: grey;
}
.pageNumbers button{
    border: none !important;
}
.pageNumbers button:disabled:hover{
    background-color: grey !important;
    cursor: not-allowed;
}


.pageNumbers li.button:focus{
    outline: none;
}