@import url(./variables.css);
@import url(./utilities.css);
@import url(./marketplace.css);
@import url(./category.css);
@import url(./products.css);
@import url(./vendor.css);
@import url(./profile.css);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
*{
    margin: 0;
}
html, body{
    margin: 0;
    padding: 0;
    font-family:Lato,sans-serif;
    /* background: var(--light-grey); */
}
p{
    color: grey;
}
/* Pagination */
.page-link{
    background: transparent;
    color: var(--dark-blue);
}
.page-item.active .page-link{
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
}

/* alert */
.alert{
    width: 300px;
    text-align: center;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    animation: moveInTop .5s ease-in;
}

@keyframes moveInTop {
    0% {
      opacity: 0;
      transform: translateX(-3rem);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
