
.profile-area{
    width: 78%;
    margin-left: 15rem;
    margin-top: 5rem;
}
/* .profile-nav{
    width: 100% !important;
}
.profile-form{
    width: 100% !important;
} */
.cursor{
    cursor: pointer;
}
.m-top{
    margin-top:2.5rem
}

.camera-icon-wrapper, .banner-icon-wrapper{
    transition: .5s ease;
    position: absolute;
    top: -30px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.61);
    cursor: pointer;
}

.camera-icon-wrapper:hover{
    opacity: 1;
}
.camera-icon-wrapper>span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

.avatar-wrapper{
    position: relative;
    width: 100px;
    height: 100px;
    margin: 50px 0 0 0;
    border-radius: 50%;
}
.avatar-wrapper>img{
    width: 100%;
    height: 100%;
    display: block;
    margin: -30px auto 0 auto;
    border-radius: 50%;
    transition: .5s ease;
    backface-visibility: hidden;
    opacity: 1;
    object-fit: cover;
}

.banner-icon-wrapper{
    border-radius: 0 !important;
}

@media(max-width:425px){
    .avatar-wrapper {
        width: 70px;
        height: 70px;
        margin: 40px -15px -25px;
    }
}
@media(min-width: 700px) and (max-width: 768px){
    .profile-form{
        /* width: 100% !important; */
    }
    /* .profile-nav{
        width: 25%;
    } */
}
@media(min-width: 1024px){
    .profile-form{
        width: 75%;
    }
}
@media(max-width:768px){
    
    .mobile-margin{
        margin-bottom: 3rem;
    }
    .profile-area{
        width: 100% !important;
        margin-left: 0 !important;
    }
}
@media(max-width:1024px){
    .w-100{
        width: 25%;
    }
    .w-100{
        width: 72%;
    }
    .profile-area{
        width: 72%;
        margin-left: 16rem;
    }
}

@media(min-width: 768px){
    .container-scroll{
        overflow-x: scroll!important;
    }
}

