/* TEXT */
.text-white{
    color: white !important;
}
.text-red{
    color: red;
}
.text-green{
    color: #42C884;
}
.text-blue{
    color: var(--blue) !important;
}
.text-dark-blue{
    color: var(--dark-blue);
}
.text-grey{
    color: var(--grey)
}
.text-light-grey{
    color: var(--light-grey);
}
.text-dark-grey{
    color: var(--dark-grey)
}
.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}

.font-bold{
    font-weight: bold;
}
.font-large{
    font-size: 2.5rem;
}
.font-size-base{
    font-size: 1rem
}/* Assumes the browser default, typically `16px` */
.font-size-sm{
    font-size: calc(1rem * .875);
}
.font-size-lg{
    font-size: calc(1rem * 1.25);
}
h1{
    font-size: calc(1rem * 2.5);
}
h2{
    font-size: calc(1rem * 2);
}
h3 {
    font-size: calc(1rem * 1.75)
}
h4{
    font-size: calc(1rem * 1.5)
}
h5{
    font-size: calc(1rem * 1.25);
}
.underline-heading{
    position: relative;
    padding: 0 0 10px 0;
}
.underline-heading:before{
    content: "";
    position: absolute;
    width: 25px;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid var(--blue);
}
.lh-base{
    line-height: 1.5rem;
}
.lh-sm{
    line-height: 1.25rem;
}
.lh-lg{
    line-height: 2;
}
.text-bold{
    font-weight: bold;
}

/* BORDER */
.border-blue{
    border: 1px solid var(--blue);
}
.border-red{
    border: 1px solid red;
}
.border-light-grey{
    border: 1px solid var(--grey) !important;
}
.rounded-3{
    border-radius: 3px;
}
.rounded-half{
    border-radius: 5rem !important; 
}
.rounded-10{
    border-radius: 10rem;
}
.rounded-full{
    border-radius: 50%;
}
.border-top{
    border-top: 1px solid var(--light-grey);
}
.border-left{
    border-left: 1px solid var(--light-grey);
}
.border-left-white{
    border-left: 1px solid white;
}
.border-right{
    border-right: 1px solid var(--light-grey);
}
.border-bottom{
    border-bottom: 1px solid var(--grey);
}
.border-dashed{
    /* border-style: dashed #E5E5E5; */
    border: 2px dashed grey;
}

/* BACKGROUND */
.bg-transparent{
    background: transparent;
}
.bg-white{
    background: white;
}
.bg-red{
    background: red;
}
.bg-green{
    background: green;
}
.bg-light-green{
    background: rgba(186, 252, 186, 0.5);
}
.bg-light-pink{
    background: rgba(255, 192, 203, 0.301);
}
.bg-light-yellow{
    background: rgba(255, 255, 147, 0.404);
}
.text-pink{
    color: rgb(190, 27, 136);
}
.text-yellow{
    color: rgb(116, 116, 11);
}
.bg-blue{
    background: var(--blue);
}
.bg-dark-blue{
    background: var(--dark-blue);
}
.bg-light-blue{
    background: var(--light-blue);
}
.bg-grey{
    background: var(--grey)
}
.bg-light-grey{
    background: var(--light-grey)
}
.bg-lighter-grey{
    background: var(--lighter-grey)
}
.bg-dark-grey{
    background: var(--dark-grey);
}
.bg-gray{
    background: var(--gray);
    opacity: .80;
}


/* BUTTONS */
button:hover{
    background: var(--dark-blue) !important;
    border: var(--dark-blue);
    color: white;
}
button{
    background: var(--blue);
    border-radius: 3px;
    color: white;
}

/* WIDTHS */
.width-10{
    width: 10px;
}
.width-20{
    width: 20px!important;
}
.width-30{
    width: 30px!important;
}
.width-40{
    width: 40px!important;
}
.width-25{
    width: 25px;
}
.width-60-px{
    width: 60px;
}
.width-50{
    width: 50px;
}
.width-60{
    width: 60%;
}
.width-20{
    width: 45%;
}
.w-100{
    width:20%
}
.width-65{
    width: 65%;
}
.width-70{
    width: 70%;
}
.w-100{
    width: 78%;
}
.width-80{
    width: 80%;
}
.width-85{
    width: 85%;
}
.width-90{
    width: 90%;
}
.width-95{
    width: 95%;
}
.width-100{
    width: 100px;
}
.width-150{
    width: 150px;
}
.w-200{
    width: 200px;
}
.w-350{
    width: 350px;
}
.w-90{
    width: 90%
}
.mw-100{
    max-width: 100%;
}
.mw-200{
    max-width: 200px;
}
.mw-300{
    max-width: 300px;
}
.mw-360{
    max-width: 360px;
}
.mw-400{
    max-width: 400px;
}
.mw-500{
    max-width: 500px;
}
.mw-600{
    max-width: 600px;
}
.mw-700{
    max-width: 700px;
}
.mw-750{
    max-width: 750px;
}
.mw-800{
    max-width: 800px;
}
.mw-900{
    max-width: 900px;
}
.mw-rem-50{
    max-width: 50rem;
}
.max-width{
    max-width: 400px;
}
.h-50{
    height: 50% !important;
}
.h-100{
    height: 100% !important;
}
.h-450{
    height: 450px !important;
}
.height-auto{
    height: auto;
}
.height-fit{
    height: fit-content;
}
.heigth-50-px{
    height: 50px;
}
.height-25{
    height: 25px;
}
.height-50{
    height: 50px;
}
.height-60{
    height: 60px;
}
.height-100{
    height: 100px;
}
.height-150{
    height: 150px;
}
.height-200{
    height: 200px !important;
}
.height-300{
    height: 300px;
}
.height-400{
    height: 400px;
}
.height-500{
    height: 500px;
}
.max-height{
    max-height: 100%;
}

/* DISPLAYS & POSITION */
.opacity-1{
    opacity: 1;
}
.flex-on-mobile{
    display: flex;
}
.hide-on-mobile{
    display: none;
}
.m-auto{
    margin: auto;
}
.block{
    display: block;
}
.inline{
    display: inline;
}
.object-fit-cover{
    object-fit: cover;
}
.object-bottom{
    object-position: bottom;
}
.object-center{
    object-position: center;
}
.cursor-pointer{
    cursor: pointer;
}
.order-1{
    order: 1;
}
.order-2{
    order: 2;
}
.z-index-100{
    z-index: 100;
}
.float-left{
    float: left;
}
.top-0{
    top: 0
}
.left-0{
    left: 0
}
.bottom-0{
    bottom: 0
}
.top-0{
    right: 0
}
.float-right{
    float: right;
}
.flex-end{
    align-items: flex-end;
}
.overflow-y{
    overflow-y: scroll;
}
.p-bottom45{
    padding-bottom: 4.5rem;
}

.logout-mag{
    margin: 3rem 0;
}

/* .width-300{
    width: 300px;
} */



@media(min-width:768px){
    .max-width{
        max-width: 360px;
    }
    .flex-on-mobile{
        display: block;
    }
    .hide-on-mobile{
        display: block;
    }
}
@media(max-width:768px){
    .w-100{
        width: 100% !important;
    }
    .logout-mag{
        margin: 0px;
    }
    .direction-col{
        flex-direction: column;
    }
    .mobile-margin{
        margin-bottom: 3rem;
    }
}

